@tailwind base;

@tailwind components;

@tailwind utilities;

/* toast theme */
:root:root:root {
  --toastify-icon-color-success: rgb(124 58 237);
  --toastify-color-progress-success: rgb(124 58 237);
}
